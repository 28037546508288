/* Content Box */
.mc_box,
.mc_attentionBox {
  margin: 0 10px 20px;
  padding: 15px 15px 0;
  background: #fff;
  box-shadow: 0 0 1px #aaa;
  -ms-box-shadow: 0 0 1px #aaa;
  border-radius: 1px;
}

/* attention */
.mc_box .attention,
.mc_attentionBox .attention {
  /* margin:0 -15px 20px; */
  margin: 0 -15px -1px;
  padding: 15px;
  background: #ffebed;
  color: $caution-color;
}
.mc_box .attention > .mc_h3,
.mc_attentionBox .attention > .mc_h3 {
  border-bottom: 2px solid $caution-color;
}
.mc_box .attention > .mc_h3 span,
.mc_attentionBox .attention > .mc_h3 span {
  color: $caution-color;
}

.mc_box .entryPreview {
  margin: 0 0 20px 0;
}

.attention-list {
  font-size: 14px;
}

/* nth Column */
.mc_colWrap > .mc_box {
  float: left;
}
