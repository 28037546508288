/* modal共通 */
.mdl .btnWrap {
  margin-bottom: 20px;
  text-align: center;
}

.ico_loading {
  display: inline-block;
  margin: 30px;
}

/* tooltip trigger */
.tooltip {
  margin-left: 5px;
  color: $caution-color !important;
  font-size: 14px;
  font-weight: bold;
}
.tpWrap {
  position: relative;
}
.j-tooltip {
  white-space: pre-line;
  padding: 10px;
  font-size: 12px;
  color: $caution-color;
  display: none;
  background: #ffebed;
  border-radius: 4px;
  box-shadow: 0 1px 10px #ccc;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 30px;
  width: 380px;
}
