/* クチコミ */
#reviews .list {
  margin-right: -10px;
  margin-bottom: 10px;
  margin-left: -10px;
  padding-top: 5px;
}
#reviews .list > li {
  float: left;
  margin: 0 10px 20px;
  width: 230px;
}
#reviews .list .mc_box {
  position: relative;
  margin: 0;
  padding: 10px 10px 0;
}
#reviews .list .mc_box .eTtl .eImgWrap {
  display: table;
  float: left;
  margin-right: 10px;
}
#reviews .list .mc_box .eTtl .eImgWrap span {
  display: table-cell;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  text-align: center;
}
#reviews .list .mc_box .eTtl .eImgWrap span .eImg {
  max-width: 38px;
  max-height: 38px;
  border: solid 1px #efefef;
}
#reviews .list .mc_box .eTtl .mc_p {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.4;
}
#reviews .list .mc_box .eTtl .mc_p a {
  display: block;
  margin: -3px 0 0 -3px;
  padding: 3px 0 3px 22px;
  background: url(/assets/images/ico_flag_small.png) 0 0 no-repeat;
}
#reviews .list .mc_box > .eImgWrap {
  display: table-cell;
  padding-bottom: 10px;
  width: 265px;
  height: 140px;
  vertical-align: middle;
  text-align: center;
}
.ie8 #reviews .list .mc_box > .eImgWrap {
  display: block;
}
#reviews .list .mc_box > .eImgWrap .eImg {
  display: inline-block;
  max-width: 265px;
  max-height: 140px;
}
#reviews .list .mc_box > .mc_p {
  margin-bottom: 0;
  line-height: 1.2;
}
#reviews .list .mc_box .arrow {
  position: absolute;
  bottom: -13px;
  left: 50px;
}
#reviews .list .bottom {
  background: #efefef;
}
#reviews .list .bottom > .uImgWrap {
  display: table;
  float: left;
}
#reviews .list .bottom > .uImgWrap span {
  display: table-cell;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  text-align: center;
}
#reviews .list .bottom > .uImgWrap span .uImg {
  display: inline-block;
  max-width: 38px;
  max-height: 38px;
  border: solid 1px #efefef;
}
#reviews .list .bottom > .mc_p {
  float: left;
  margin-left: 10px;
  padding-top: 9px;
  width: 130px;
  font-size: 14px;
}
#reviews .list .bottom > .media {
  float: right;
  width: 30px;
  height: 30px;
  margin: 10px 4px 0 0;
}
#reviews .list .bottom .icon_feed,
#reviews .list .bottom .icon_facebook,
#reviews .list .bottom .icon_twitter,
#reviews .list .bottom .fa-rss-square,
#reviews .list .bottom .fa-facebook-square,
#reviews .list .bottom .fa-twitter-square {
  font-size: 20px;
  padding: 5px 0 0 5px;
}
