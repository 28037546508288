/* li */
.mc_list {
  padding-bottom: 20px;
}
.mc_list li {
  /* margin-bottom:3px; */
  padding-left: 8px;
  background: url(/assets/images/ico_indent_gray.png) 0 10px no-repeat;
  font-size: 14px;
}
/* ol */
.mc_ol_conter {
  counter-reset: number;
  padding-bottom: 20px;
}
.mc_ol_conter li {
  /* margin-bottom:3px; */
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
  background: #fff;
  -webkit-border-radius: 16px;
  border-radius: 16px;
  padding: 5px;
}
.mc_ol_conter li:before {
  counter-increment: number;
  content: counter(number);
  display: inline-block;
  width: 16px;
  height: 16px;
  background: $primary-color;
  text-align: center;
  color: #fff;
  -webkit-border-radius: 16px;
  border-radius: 16px;
  margin-right: 8px;
}
/* .mc_list li:before {
  padding-right:5px; */
/* color:#ccc; */
/* content:"-"; */
.mc_list .mc_a {
  font-size: 10px;
}
