/* Pager */
.mc_pager {
  text-align: center;
}
.mc_pager li {
  display: inline-block;
  margin: 0 5px;
  color: #666;
  font-size: 12px;
  line-height: 1.9em;
}
.mc_pager li a {
  display: inline-block;
  padding: 0 5px;
  min-width: 10px;
  min-height: 20px;
  border: solid 1px #efefef;
  border-radius: 2px;
}
.mc_pager li a[href]:hover,
.mc_pager li a[onclick]:hover {
  border: solid 1px #ccc;
  border-radius: 2px;
}
.mc_pager li.first,
.mc_pager li.prev,
.mc_pager li.last,
.mc_pager li.next {
  width: 20px;
  height: 20px;
}
.mc_pager li.first a,
.mc_pager li.prev a,
.mc_pager li.last a,
.mc_pager li.next a {
  padding: 0;
  width: 20px;
  height: 20px;
  text-indent: -1000em;
}
.mc_pager li.first a {
  background: url(/assets/images/ico_linkWArrowL.png) center center no-repeat;
}
.mc_pager li.prev a {
  background: url(/assets/images/ico_linkArrowL.png) center center no-repeat;
}
.mc_pager li.last a {
  background: url(/assets/images/ico_linkWArrowR.png) center center no-repeat;
}
.mc_pager li.next a {
  background: url(/assets/images/ico_linkArrowR.png) center center no-repeat;
}
.mc_pager li.current a {
  border: 0;
  background: $primary-color;
  color: #fff;
}
.mc_pager li.disable a {
  background-color: #efefef;
  color: #ccc;
}
