/* Paragragh */
.mc_p {
  /* display:inline-block; */
  margin-bottom: 10px;
  font-size: 14px;
}

/* small */
.mc_p.small {
  font-size: 10px;
}

.mc_p.medium {
  font-size: 12px;
}

/* large */
.mc_p.large {
  font-size: 14px;
}

/* default */
.mc_p.default {
  color: #666;
}

/* red */
.mc_p.red {
  color: $caution-color;
}

/* orange */
.mc_p.orange {
  color: #fd871a;
}

/* lime */
.mc_p.lime {
  color: #bfda00;
}

/* green */
.mc_p.green {
  color: #5eb31e;
}

/* Notice */
.mc_notice {
  /* display:inline-block; */
  margin-bottom: 10px;
  color: #696969;
  font-size: 12px;
}

/* Answer */
.mc_answer {
  display: inline-block;
  padding: 3px 10px;
  border-radius: 4px;
  background: #fdf6f0;
  color: #333;
  white-space: pre-line;
  font-size: 14px;
}
.mc_answer.empty {
  background: none;
}
