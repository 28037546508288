/* category styles
**************************************************/
.signup__btn {
  width: 100%;
  display: inline-block;
  height: 44px;
  line-height: 44px;
  background: #222;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.signup__btn:link,
.signup__btn:active,
.signup__btn:visited,
.signup__btn:hover {
  color: #fff;
  text-decoration: none !important;
}
.signup__btn--fb {
  background: #4377cd;
}
.signup__btn--tw {
  background: #03aed9;
}
.signup__btn--blog {
  background: #cd4343;
}
.signup__btn--blog {
  background: #cd4343;
}
.signup__btn--insta {
  background: #f1ba2f;
}
.signup__btn--fs16 {
  font-size: 16px;
}

/* signup */
#signup #wrapper {
  max-width: 800px;
  margin: 80px auto 0;
}
.signup__bg {
  position: absolute;
  top: -30px;
  left: 0;
  background: url(/assets/images/about/bg6.png) repeat-x;
  width: 100%;
  height: 460px;
}
.signup__bg--inner {
  background: #fff;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 200px;
  margin-top: -100px;
}
.signupWrap {
  margin-bottom: 60px;
  padding-top: 30px;
}
.eyecatch {
  padding-top: 120px;
}
.eyecatch__text {
  font-size: 28px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 16px;
}
.eyecatch__subtext {
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 16px;
}
.eyecatch__link {
  text-align: right;
}

.signupForm {
  padding: 20px;
  position: relative;
}
.signupForm__title {
  font-size: 26px;
  span {
    color: #fff;
    background: $primary-color;
    padding: 4px 8px;
    border-radius: 4px;
    margin-right: 16px;
  }
}
.signupForm__text {
  margin-bottom: 6px;
  color: #aaa;
}
.signupBtns {
  overflow: hidden;
  margin-bottom: 20px;
}
.signupBtns__list {

}
.orText {
  border-bottom: 1px #ccc solid;
  text-align: center;
  position: relative;
  margin-bottom: 20px;
}
.orText__text {
  background: #fff;
  display: inline-block;
  padding: 5px;
  position: absolute;
  left: 50%;
  top: -14px;
  margin-left: -20px;
}

.signupInput {
  margin-bottom: 10px;
}
.signupInput__title {
  font-size: 14px;
  font-weight: bold;
}
.signupForm__submit {
  text-align: center;
  margin-bottom: 20px;
}
.icon__free {
  width: 80px;
  height: 80px;
  background: url(/assets/images/signup_icon_free.png) no-repeat;
  position: absolute;
  top: -30px;
  left: -30px;
}
.signupFAQ {
  margin-bottom: 60px;
}
.signupFAQ__title {
  height: 60px;
  margin-bottom: 10px;
}
.signupFAQ__h2 {
  font-size: 24px;
  font-weight: bold;
  padding-top: 10px;
  line-height: 1.2;
}
.signupFAQ__text {
  color: #999;
}
.signupFAQ__qa {
  border: 5px solid $basic-color;
  padding: 15px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.signupFAQ__qa__dl {

}
.signupFAQ__qa--question {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  background: #f4f0e5 url(/assets/images/signup_icon_q.png) 10px 10px no-repeat;
  padding: 5px 0 5px 40px;
  margin-bottom: 10px;
}
.signupFAQ__qa--answer {
  font-size: 14px;
  margin: 0 20px 20px;
}

.loginForm__title {
  font-size: 16px;
  color: #565656;
  font-weight: bold;
  padding-bottom: 5px;
}
.loginForm__submit {
  margin-top: 5px;
}
.login_box {
  margin-bottom: 16px;
}
#autoLoginWrap {
  width: 165px;
  float: left;
  border-right: 1px solid #eceae5;
}
.loginLinkWrap {
  margin: 5px 16px;
}

.title_h2 {
  margin: 0 -16px;
  margin-bottom: 16px;
  padding: 16px;
  text-align: center;
  border-bottom: solid 1px $base-color-border;
}

.title_h2 > span {
  color: #333;
  font-size: 20px;
  font-weight: bold;
}

/* common login , modal login */
.login-mail-area {
  margin-bottom: 20px;
}
.login-mail-area input[type="text"] {
  margin-bottom: 20px;
}
.login-mail-area input[type="text"],
.login-mail-area input[type="password"] {
  padding: 12px 6px 8px 12px;
  border: 1px solid #b0b0b0;
  border-radius: 4px;
  line-height: 1;
  color: #000;
  box-shadow: none;
  outline: none;
  width: 333px;
  outline: none;
}
.login-mail-area input[type="text"]:focus,
.login-mail-area input[type="password"]:focus {
  background: #fffbeb;
  border: 2px solid $primary-color;
  padding: 11px 5px 7px 11px;
  outline: none;
}
.login-mail-box,
.login-SNS-box {
  padding: 16px;
  background-color: $base-color-border;
  border-radius: 5px;
}
.loginForm__text {
  padding: 16px 0;
}
.loginBtns__list:last-child {
  height: 36px;
  margin-bottom: 0px;
}
.loginBtns__list {
  height: 36px;
  margin-bottom: 9px;
}
.login__btn-box {
  margin-top: 5px;
  text-align: center;
}
.login__btn {
  float: left;
  width: 100%;
  height: 36px;
  line-height: 36px;
  background: #222;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 3px;
}
.login__btn:link,
.login__btn:active,
.login__btn:visited,
.login__btn:hover {
  color: #fff;
  text-decoration: none !important;
}
.login__btn--fs16 {
  font-size: 16px;
  height: 44px;
  line-height: 44px;
}
.login__btn--fb {
  background: #4377cd;
}
.login__btn--tw {
  background: #54c5f3;
}
.login__btn--blog {
  background: #d34a46;
}
.login__btn--insta {
  background: #f1ba2f;
}
.icon--fb {
  background: url(/assets/images/login/login-icon.png) no-repeat 7px center #1c5090;
}
.icon--tw {
  background: url(/assets/images/login/login-icon.png) no-repeat -22px center #3baad8;
}
.icon--blog {
  background: url(/assets/images/login/login-icon.png) no-repeat -53px center #ca3632;
}
.icon--insta {
  background: url(/assets/images/login/login-icon.png) no-repeat -85px center #daa729;
}
.icon--tiktok {
  background: url(/assets/images/sns/tiktok.svg) no-repeat -85px center #daa729;
}

.icon--fb,
.icon--tw,
.icon--blog,
.icon--insta,
.icon--tiktok {
  width: 36px;
  height: 36px;
  text-decoration: none;
  -webkit-border-radius: 5px;
  border-radius: 5px 0px 0px 5px;
  margin-bottom: 3px;
  float: left;
}
.login__btn-area {
  float: left;
  margin-left: 12px;
}
.login__btn-text {
  font-size: 14px;
  float: left;
  margin-left: 12px;
}

/* SECURITY
**************************************************/
.login_security {
  background: #fff;
  font-size: 14px;
  text-align: center;
  margin-top: 26px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.login_security a {
  display: block;
  padding: 4px 0;
}
.login_security a:hover {
  text-decoration: none;
}
.login_security .icon_warning2 {
  color: #dab432;
  margin-right: 4px;
  font-size: 14px;
}


@media (max-width: 480px) {
  #signup #wrapper {
    margin: 60px auto 0;
  }
}