/* hx */
/* green(default) */
.mc_h1 {
  margin: 0 -16px;
  margin-bottom: 16px;
  padding: 16px;
  text-align: center;
  border-bottom: solid 1px $base-color-border;
  //background: url(/assets/images/hx_dottedLineS_green.png) 0 bottom repeat-x;
}
.mc_h1 > span {
  color: #333;
  font-size: 20px;
}
.mc_h2 {
  margin-bottom: 10px;
  padding: 0;
  border-bottom: solid 2px $primary-color;
  //background: url(/assets/images/hx_dottedLineL_green.png) 0 bottom repeat-x;
}
.mc_h2 > span {
  display: inline-block;
  padding: 0 0 3px;
  //border-bottom: solid 4px $primary-color;
  color: #333;
  font-size: 16px;
  font-weight: bold;
}
.mc_h2 > span.starIcon {
  //background: url(/assets/images/icon_h1back.png) 0 -2px no-repeat;
  padding-left: 30px;
}
.mc_h2 span span[class^="icon_"],
.mc_h2 span i[class^="fa"] {
  padding-right: 4px;
}
.mc_h3 {
  margin-bottom: 10px;
  padding: 0;
  border-bottom: solid 2px $primary-color;
  //background: url(/assets/images/hx_dottedLineS_green.png) 0 bottom repeat-x;
}
.mc_h3 > span {
  display: inline-block;
  padding: 0 0 3px;
  //border-bottom: solid 2px $primary-color;
  color: #333;
  font-size: 16px;
  font-weight: bold;
}
.mc_h3 > .go2list {
  float: right;
}
.mc_h4 {
  margin-bottom: 10px;
  /* margin-bottom:10px; */
  padding: 10px 0 0;
  /* padding:10px 0 0; */
  /* background:url(/assets/images/hx_dottedLineS_lime.png) 0 bottom repeat-x; */
}
.mc_h4 > span {
  display: inline-block;
  /* display:inline-block; */
  padding: 3px 0 3px 8px;
  /* padding:10px 0 3px; */
  /* border-bottom:solid 2px #BFDA00; */
  border-left: solid 4px $primary-color;
  color: #333;
  /* color:#333; */
  font-size: 14px;
  /* font-size:14px; */
  font-weight: bold;
  /* font-weight:bold; */
  line-height: 1.2;
  /* line-height:1.2; */
}
.mc_h5 {
  margin-bottom: 10px;
  /* margin-bottom:10px; */
  padding: 10px 0 0;
  /* padding:10px 0 0; */
  /* background:url(/assets/images/hx_dottedLineS_green.png) 0 bottom repeat-x; */
}
.mc_h5 > span {
  display: inline-block;
  /* display:inline-block; */
  padding: 0 0 0 6px;
  /* padding:10px 0 3px; */
  /* border-bottom:solid 2px #5EB31E; */
  border-left: solid 3px $primary-color;
  color: #333;
  /* color:#333; */
  font-size: 14px;
  /* font-size:14px; */
  font-weight: bold;
  /* font-weight:bold; */
  line-height: 1;
}
.mc_h6 {
  margin-bottom: 10px;
  /* margin-bottom:10px; */
  padding: 10px 0 0;
  /* padding:10px 0 0; */
  /* background:url(/assets/images/hx_dottedLineS_green.png) 0 bottom repeat-x; */
}
.mc_h6 > span {
  display: inline-block;
  /* display:inline-block; */
  padding: 1px 0 0 5px;
  /* padding:10px 0 3px; */
  /* border-bottom:solid 2px #5EB31E; */
  border-left: solid 2px $primary-color;
  color: #333;
  /* color:#333; */
  font-size: 14px;
  /* font-size:14px; */
  font-weight: bold;
  /* font-weight:bold; */
  line-height: 1;
}

/* lime */
//.mc_h1.lime {
//  border-top: solid 4px #bfda00;
//  background: url(/assets/images/hx_dottedLineS_lime.png) 0 bottom repeat-x;
//}
//.mc_h1.lime span {
//}
//.mc_h2.lime {
//  background: url(/assets/images/hx_dottedLineL_lime.png) 0 bottom repeat-x;
//}
//.mc_h2.lime span {
//  border-bottom: solid 4px #bfda00;
//}
//.mc_h3.lime {
//  background: url(/assets/images/hx_dottedLineS_lime.png) 0 bottom repeat-x;
//}
//.mc_h3.lime span {
//  border-bottom: solid 2px #bfda00;
//}
//.mc_h4.lime {
//}
//.mc_h4.lime span {
//  border-left: solid 4px #bfda00;
//}
//.mc_h5.lime {
//}
//.mc_h5.lime span {
//  border-left: solid 3px #bfda00;
//}
//.mc_h6.lime {
//}
//.mc_h6.lime span {
//  border-left: solid 2px #bfda00;
//}

/* gray */
.mc_h1.gray {
  border-top: solid 4px #999;
  border-bottom: solid 2px #999;
}
.mc_h1.gray span {
}
.mc_h2.gray {
  border-bottom: solid 2px #999;
}
.mc_h2.gray span {
  border-bottom: solid 4px #999;
}
.mc_h3.gray {
  border-bottom: solid 2px #999;
}
.mc_h3.gray span {

}
.mc_h4.gray {
}
.mc_h4.gray span {
  border-left: solid 4px #999;
}
.mc_h5.gray {
}
.mc_h5.gray span {
  border-left: solid 3px #999;
}
.mc_h6.gray {
}
.mc_h6.gray span {
  border-left: solid 2px #999;
}

/* red */
.mc_h1.red {
  border-top: solid 4px $caution-color;
  border-bottom: solid 2px $caution-color;
}
.mc_h1.red span {
  color: $caution-color;
}
.mc_h2.red {
  border-bottom: solid 2px $caution-color;
}
.mc_h2.red span {
  color: $caution-color;
}
.mc_h3.red {
  border-bottom: solid 2px $caution-color;
}
.mc_h3.red span {
  color: $caution-color;
}
.mc_h4.red {
}
.mc_h4.red span {
  border-left: solid 4px $caution-color;
  color: $caution-color;
}
.mc_h5.red {
}
.mc_h5.red span {
  border-left: solid 3px $caution-color;
  color: $caution-color;
}
.mc_h6.red {
}
.mc_h6.red span {
  border-left: solid 2px $caution-color;
  color: $caution-color;
}
