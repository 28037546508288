/* 汎用button height30px */
.mc_btnH30 {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 8px 16px;
  border: none;
  vertical-align: bottom;
  text-decoration: none !important;
  background: $primary-color;
  border: 2px solid $primary-color;
  font-weight: bold;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-font-smoothing: antialiased;
}
.mc_btnH30:hover {
  text-decoration: none !important;
  opacity: 0.75 !important;
}
.mc_btnH30:active {
  position: static;
  top: 0;
  background: $primary-color;
}
.mc_btnH30 span {
  color: #fff;
  vertical-align: 0;
  font-size: 14px;
}

/* active */
.mc_btnH30.active {
  background: #fff;
  border: 2px solid $primary-color;
}
.mc_btnH30.active span {
  color: $base-color-text;
}

/* disable */
.mc_btnH30.disable {
  background: #ddd;
  box-shadow: none;
  border: 2px solid #bbb;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.mc_btnH30.disable span {
  color: #666;
  font-size: 14px;
}

/* basic */
.mc_btnH30.basic {
  background: #fff;
  border: 2px solid $base-color-border;
}
.mc_btnH30.basic span {
  color: #333;
}

/* basic */
.mc_btnH30.secondary {
  background: #FFFBF2;
  border: 2px solid $primary-color;
}
.mc_btnH30.secondary span {
  color: $primary-color;
}

.mc_btnH30.secondary:hover {
  background: $primary-color;
  border: 2px solid $primary-color;
}
.mc_btnH30.secondary:hover span {
  color: #fff;
}

/* 汎用button height40px */
.mc_btnH40 {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0 40px;
  line-height: 44px;
  border: none;
  font-weight: bold;
  text-align: center;
  vertical-align: bottom;
  text-decoration: none !important;
  background: $primary-color;
  border: 2px solid $primary-color;
  font-weight: bold;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-font-smoothing: antialiased;
}
.mc_btnH40:hover {
  text-decoration: none !important;
  opacity: 0.75 !important;
}
.mc_btnH40:active {
  position: static;
  top: 0;
  outline: none;
}
.mc_btnH40 span {
  color: #fff;
  font-size: 16px;
}

/* active */
.mc_btnH40.active {
  background: #fff;
  border: 2px solid $base-color-border;
}
.mc_btnH40.active span {
  color: #333;
}

/* disable */
.mc_btnH40.disable {
  background: #ddd;
  box-shadow: none;
  border: 2px solid #bbb;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.mc_btnH40.disable span {
  color: #666;
  font-size: 16px;
}

/* basic */
.mc_btnH40.basic {
  background: #fff;
  border: 2px solid $base-color-border;
}
.mc_btnH40.basic span {
  color: #333;
  font-size: 16px;
}

.mc_btnH40.secondary {
  background: #FFFBF2;
  border: 2px solid $primary-color;
}
.mc_btnH40.secondary span {
  color: $primary-color;
}

.mc_btnH40.secondary:hover {
  background: $primary-color;
  border: 2px solid $primary-color;
}
.mc_btnH40.secondary:hover span {
  color: #fff;
}

/* 汎用button height40px */
.mc_btnH50 {
  position: relative;
  display: inline-block;
  padding: 0 40px;
  height: 56px;
  line-height: 54px;
  border: none;
  text-align: center;
  vertical-align: bottom;
  text-decoration: none !important;
  background: $primary-color;
  border: 2px solid $primary-color;
  font-weight: bold;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-font-smoothing: antialiased;
}
.mc_btnH50:hover {
  text-decoration: none !important;
  opacity: 0.75 !important;
}
.mc_btnH50:active {
  position: static;
  top: 0;
  outline: none;
}
.mc_btnH50 span {
  color: #fff;
  font-size: 16px;
}

/* active */
.mc_btnH50.active {
  background: #fff;
  border: 2px solid $primary-color;
}
.mc_btnH50.active span {
  color: #333;
}

/* disable */
.mc_btnH50.disable {
  background: #ddd;
  box-shadow: none;
  border: 2px solid #bbb;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.mc_btnH50.disable span {
  color: #666;
  font-size: 16px;
}

/* basic */
.mc_btnH50.basic {
  background: #fff;
  border: 2px solid $base-color-border;
}
.mc_btnH50.basic span {
  color: #333;
  font-size: 16px;
}

.mc_btnH50.secondary {
  background: #FFFBF2;
  border: 2px solid $primary-color;
}
.mc_btnH50.secondary span {
  color: $primary-color;
}

.mc_btnH50.secondary:hover {
  background: $primary-color;
  border: 2px solid $primary-color;
}
.mc_btnH50.secondary:hover span {
  color: #fff;
}

/* 汎用button height25px */
.mc_btnH25 {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0 20px;
  height: 25px;
  border: none;
  vertical-align: bottom;
  text-decoration: none !important;
  background: $primary-color;
  font-weight: bold;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-font-smoothing: antialiased;
}
.mc_btnH25:hover {
  text-decoration: none !important;
  opacity: 0.75 !important;
}
.mc_btnH25:active {
  position: static;
  top: 0;
}
.mc_btnH25 span {
  color: #fff;
  vertical-align: 0;
  font-size: 14px;
}
a.mc_btnH25 span {
  line-height: 30px;
}

/* active */
.mc_btnH25.active {
  background: #fff;
  border: 1px solid $primary-color;
}
.mc_btnH25.active span {
  color: #333;
}

/* disable */
.mc_btnH25.disable {
  background: #ddd;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.mc_btnH25.disable span {
  color: #666;
  font-weight: normal;
  font-size: 14px;
}

/* basic */
.mc_btnH25.basic {
  background: url(/assets/images/bt_basic_h30C.png) 0 0 repeat-x;
}
.mc_btnH25.basic span {
  color: #666;
}

.mc_btnH25.secondary {
  background: #FFFBF2;
  border: 2px solid $primary-color;
}
.mc_btnH25.secondary span {
  color: $primary-color;
}

.mc_btnH25.secondary:hover {
  background: $primary-color;
  border: 2px solid $primary-color;
}
.mc_btnH25.secondary:hover span {
  color: #fff;
}

/* facebook */
.mc_btnH40.facebook {
  background: url(/assets/images/bt_facebook_h40C.png) 0 0 repeat-x;
  padding: 0 20px 0 40px;
}
.mc_btnH40.facebook span {
  margin-left: 10px;
}
.mc_btnH40.facebook img {
  position: absolute;
  left: 20px;
  top: 10px;
}
.mc_btnH40.twitter {
  background: url(/assets/images/bt_twitter_h40C.png) 0 0 repeat-x;
  padding: 0 20px 0 40px;
}
.mc_btnH40.twitter span {
  margin-left: 10px;
}
.mc_btnH40.twitter img {
  position: absolute;
  left: 20px;
  top: 10px;
}

/*npc_fb*/
.mc_btnH40.npc_fb {
  background: url(/assets/images/bt_facebook_h40C.png) 0 0 repeat-x;
}
.mc_btnH40.npc_fb span {
  background: url(/assets/images/iconSprite_snsBtn.png) 0 0 no-repeat;
}

/*npc_tw*/
.mc_btnH40.npc_tw {
  background: url(/assets/images/bt_twitter_h40C.png) 0 0 repeat-x;
}
.mc_btnH40.npc_tw span {
  background: url(/assets/images/iconSprite_snsBtn.png) 0 -40px no-repeat;
}

/*npc_blog*/
.mc_btnH40.npc_blog {
  background: url(/assets/images/bt_blog_h40C.png) 0 0 repeat-x;
}
.mc_btnH40.npc_blog span {
  background: url(/assets/images/iconSprite_snsBtn.png) 0 -80px no-repeat;
}

#loginByOauth .mc_btnH40.snsBtn {
  display: block;
  height: 40px;
  line-height: 40px;
  padding: 0;
}
#loginByOauth .mc_btnH40.snsBtn span {
  display: block;
  line-height: 40px;
  padding-left: 40px;
}

.mc_btnMore {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 16px 24px;
  text-align: center;
  border: none;
  vertical-align: bottom;
  text-decoration: none !important;
  background: $info-color;
  font-weight: bold;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-font-smoothing: antialiased;
  min-width: 180px;
}
.mc_btnMore:hover {
  text-decoration: none !important;
  opacity: 0.75 !important;
}
.mc_btnMore:active {
  position: static;
  top: 0;
  background: #fff;
}
.mc_btnMore span {
  color: $primary-color;
  vertical-align: 0;
  font-size: 16px;
  i {
    color: $base-color-text;
    opacity: .4;
  }
}