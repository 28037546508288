// ---------------------------------------------------------------
//  Base-Size
// ---------------------------------------------------------------
$base-size: 1;
$max-width: 960px;
$content-width: 800px;
$breakpoint: 480px;

// ---------------------------------------------------------------
//  Font
// ---------------------------------------------------------------
$base-font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI",
  "Helvetica Neue", HelveticaNeue, "游ゴシック体", YuGothic, "游ゴシック Medium",
  "Yu Gothic Medium", "游ゴシック", "Yu Gothic", Verdana, "メイリオ", Meiryo,
  sans-serif;

// ---------------------------------------------------------------
//  Line-hieght
// ---------------------------------------------------------------
$line-height-base: 1.6;

// ---------------------------------------------------------------
//  Base-color
// ---------------------------------------------------------------
$base-color-bg: #fff;
$base-color-text: #222;
$base-color-border: #f0f0f0;
$base-color-link: #4382dd;

// ---------------------------------------------------------------
//  Content-color
// ---------------------------------------------------------------
//$primary-color: #E8C158;
$primary-color: #FAA14F;
$success-color: #4382dd;
$caution-color: #dd4343;
$info-color: #f7f5f0;
$basic-color: #f2ebde;

// ---------------------------------------------------------------
//  Flex
// ---------------------------------------------------------------
$var-breakpoint: $breakpoint;
$var-col: 12;
$var-max-width: 100%;
$var-col-gutters: 3%;
$var-col-width: $var-max-width / $var-col;
$var-col-width-gutters: ($var-max-width + $var-col-gutters) / $var-col;
