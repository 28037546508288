.c-panel {
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);
}

.c-panel__content {
  padding: 16px;
}

.c-panel__image {
  margin-botto: 16px;

  > img {
    width: 100%;
  }
}

.c-panel__bottom {
  padding: 16px;
  border-top: 1px solid $base-color-border;
  text-align: center;
}

.c-panel__small {
  padding: 8px;
}

.c-panel__title {
  padding: 16px;
  border-bottom: 1px solid $base-color-border;
  text-align: center;
}

.c-panel__title-middle {
  padding: 16px;
  border-top: 1px solid $base-color-border;
  border-bottom: 1px solid $base-color-border;
  text-align: center;
}