/* breadcrumbs */
#breadCrumbs {
  margin: 10px;
  padding-top: 1px;
  max-width: 100%;
  max-height: 24px;
  overflow: hidden;
  display: flex;
}
#breadCrumbs li {
  //float: left;
  padding-left: 28px;
  background: url(/assets/images/ico_linkArrowR.png) 7px center no-repeat;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#breadCrumbs li:first-child {
  margin: 0;
  padding: 0;
  background: none;
  width: 2em;
}
#breadCrumbs li:first-child a {
  display: block;
}

@media (max-width: 640px) {
  #wrapper-960 #breadCrumbs {
    display: none;
  }
}
