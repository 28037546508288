/* clearfix */
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clearfix {
  display: inline-table;
  min-height: 1%;
}
* html .clearfix {
  height: 1%;
}
.clearfix {
  display: block;
}

/* hide_text */
.hide_text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

/* default styles
**************************************************/
html,
body {
  width: 100%;
  height: 100%;
}

body {
  position: relative;
  background: #f8f8f8;
  color: $base-color-text;
  font-size: 14px !important;
  font-family: $base-font-family;
  line-height: $line-height-base;
  -webkit-font-smoothing: antialiased;
}

/* img */
img {
  /* display:block; */
  vertical-align: bottom;
}

/* text */
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
textarea,
dt,
dd,
th,
td,
li,
a,
div {
  word-break: break-all;
}

/* noscript */
#noscript_message {
  margin: 65px 0 20px 0;
  padding: 10px 0;
  background: #fff9d1;
  font-size: 16px;
  text-align: center;
}
#noscript_message p a {
  color: $success-color;
  text-decoration: underline;
}

/* teleport */
.teleport {
  height: 1px;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}
#header .teleport:active,
#header .teleport:focus,
#header .teleport:hover {
  background: #f9edbe;
  border: 1px solid #f0c36d;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
  height: auto;
  left: 0;
  margin: 5px auto;
  max-width: 180px;
  padding: 6px;
  right: 0;
  text-align: center;
  width: auto;
  z-index: 3;
}

/* unsupported browser */
.unsupported_ie {
  margin-top: 45px;
  padding: 10px 0;
  background: #fff9d1;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #bfb18d;
}
.unsupported_ie p a {
  color: $success-color;
  text-decoration: underline;
}
.unsupported_ie .icon_warning2 {
  color: #bfb18d;
  padding: 0 3px 0 0;
}

/* a */
a {
  /* text-decoration:none; */
}
a,
a:link,
a:visited,
a:hover,
a:active {
  color: $base-color-text;
  text-decoration: none;
}
a[href]:hover,
a[onclick]:hover {
  text-decoration: underline;
  /* text-decoration:underline; */
  opacity: 0.75; /* Standard: FF gt 1.5, Opera, Safari */
  -ms-filter: "alpha(opacity=75)"; /* IE 8 */
  filter: alpha(opacity=75); /* IE lt 8 */
  -khtml-opacity: 0.75; /* Safari 1.x */
  -moz-opacity: 0.75; /* FF lt 1.5, Netscape */
}
a[href]:hover:before,
a[onclick]:hover:before,
a[href]:hover:after,
a[onclick]:hover:after {
  opacity: 0.99; /* Standard: FF gt 1.5, Opera, Safari */
  -ms-filter: "alpha(opacity=99)"; /* IE 8 */
  filter: alpha(opacity=99); /* IE lt 8 */
  -khtml-opacity: 0.99; /* Safari 1.x */
  -moz-opacity: 0.99; /* FF lt 1.5, Netscape */
}

a.uLine,
a.uLine:link,
a.uLine:visited,
a.uLine:active {
  color: $base-color-text;
  text-decoration: underline;
}
a.uLine:hover {
  text-decoration: none;
}

a:hover > img {
  opacity: .75;
}

/* button */
button {
  text-decoration: none;
  /*font-family:'Lucida Grande','Hiragino Kaku Gothic ProN',Meiryo,"メイリオ", sans-serif !important;*/
}
button:hover {
  text-decoration: underline;
  opacity: 0.75; /* Standard: FF gt 1.5, Opera, Safari */
  -ms-filter: "alpha(opacity=75)"; /* IE 8 */
  filter: alpha(opacity=75); /* IE lt 8 */
  cursor: pointer;
  -khtml-opacity: 0.75; /* Safari 1.x */
  -moz-opacity: 0.75; /* FF lt 1.5, Netscape */
}
button:hover:before,
button:hover:after {
  opacity: 0.99; /* Standard: FF gt 1.5, Opera, Safari */
  -ms-filter: "alpha(opacity=99)"; /* IE 8 */
  filter: alpha(opacity=99); /* IE lt 8 */
  -khtml-opacity: 0.99; /* Safari 1.x */
  -moz-opacity: 0.99; /* FF lt 1.5, Netscape */
}
button:active {
  position: relative;
  top: 1px;
}

/* form elements */
input[type="text"],
input[type="password"],
textarea,
select {
  padding: 16px;
  border: solid 1px $base-color-border;
  border-radius: 4px;
  font-size: 14px;
  width: 100% !important;
  box-sizing: border-box;
  &:focus {
    background: #fffbeb;
    outline: none;
    border: 1px solid $primary-color;
    padding: 11px 5px 7px 11px;
  }
}
input[type="text"],
input[type="password"],
textarea,
select,
input[type="file"],
input[type="checkbox"],
input[type="radio"] {
  margin: 0;
}
textarea {
  height: 160px;
}
select {
  margin-bottom: 8px;
}
.mc_cbGroup li,
.mc_rdGroup li {
  float: left;
  margin-top: 5px;
  margin-right: 20px;
  margin-bottom: 5px;
  min-width: 160px;
}
.mc_cbGroup li:first-child,
.mc_rdGroup li:first-child {
  margin-left: 0;
}
label {
  margin-right: 5px;
  margin-left: 5px;
  font-size: 14px;
  cursor: pointer;
}
.fileWrap {
  padding: 12px 15px 15px;
  border-radius: 4px;
  background: #efefef;
}
.inputcheck_area input[type="text"]:focus,
.inputcheck_area input[type="password"]:focus {
  background: #fffbeb;
  border: 1px solid $success-color;
  padding: 9px 3px 5px 9px;
}
