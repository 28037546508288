/* footer */
#go2Top {
  position: fixed;
  right: 20px;
  bottom: 50px;
  z-index: 2;
  display: none;
  width: 60px;
  height: 60px;
  background: url(/assets/images/ico_scrollTop.png) 0 0 no-repeat;
  text-indent: -1000em;
}

#footer {
  padding: 24px;
  background: #f8f8f8;
}
#footer > .inner {
  margin: 0 auto 20px;
  width: 100%;
  max-width: $max-width;
}

#footer .ftMiddle > .inner {
  margin: 0 auto;
  padding: 10px 0 10px;
  width: 100%;
  max-width: $max-width;
}

#footer .links_area {
  margin-bottom: 10px;
  text-align: center;
}
#footer .links_area li {
  font-size: 14px;
  line-height: 2;
  display: inline-block;
  margin: 0 16px;
}

#footer .links_area li a {
  padding-right: 15px;
  background: url(/assets/images/ico_linkArrowR.png) 100% 2px no-repeat;
}

#footer .copyright {
  color: #696969;
  text-align: center;
  line-height: 2.4;
}

@media (max-width: 480px) {
  #footer {
    padding: 24px 0;
    background: #f8f8f8;
  }
  #footer .links_area {
    margin-bottom: 0;
    text-align: left;
    border-bottom: solid 1px $base-color-border;
  }
  #footer .links_area li {
    display: block;
    margin: 0;
  }
  #footer .links_area li a {
    padding: 16px;
    display: block;
    border-top: solid 1px $base-color-border;
    background: url(/assets/images/ico_linkArrowR.png) 100% 24px no-repeat;
  }
}
