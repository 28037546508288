/* table */
.mc_table {
  margin-bottom: 20px;
  width: 100%;
}
.mc_table > tbody > tr {
  border-bottom: solid 1px #efefef;
}
.mc_table > tbody > tr:first-child {
  border-top: solid 2px #efefef;
}
.mc_table > tbody > tr > th,
.mc_table > tbody > tr > td {
  padding: 10px 10px 15px 10px;
}
/* .mc_table th span,
.mc_table td span {
  font-size:1.17em; */

/* npc_table */
.npc_table {
  width: 100%;
  margin-bottom: 14px;
}


@media (max-width: 640px) {
  .mc_table > tbody > tr {
    border: 0;
    display: block;
  }
  .mc_table > tbody > tr:first-child {
    border: 0;
    display: block;
  }
  .mc_table > tbody > tr > th,
  .mc_table > tbody > tr > td {
    display: block;
  }

  .mc_table > tbody > tr > th {
    background: #fafafa;
    display: block;
  }

  //.mc_table > tbody > tr > td {
  //  padding: 10px 0 15px 0;
  //}
}