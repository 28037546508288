/* おすすめ
**************************************************/
#recommended .mc_h3 > .go2list {
  float: right;
}
#recommended .list {
  margin: 35px -10px 20px;
}
#recommended .list > li {
  position: relative;
  float: left;
  margin: 0 10px;
}
#recommended .list > li .eImgWrap {
  display: table;
  margin-bottom: 5px;
}
#recommended .list > li .eImgWrap span {
  display: table-cell;
  width: 157px;
  height: 157px;
  vertical-align: middle;
  text-align: center;
}
#recommended .list > li .eImgWrap span .eImg {
  max-width: 155px;
  max-height: 155px;
  border: solid 1px #efefef;
}
#recommended .list > li .eName {
  width: 157px;
  word-break: break-all;
  font-size: 14px;
  line-height: 1.4;
}
#recommended .vCountDown {
  position: absolute;
  top: -24px;
  left: 33px;
  width: 90px;
  height: 47px;
  background: url(/assets/images/eventItem_vBalloon.png) 0 0 no-repeat;
  color: $caution-color;
  text-align: center;
  font-size: 12px;
  line-height: 2.4;
}
#recommended .vCountDown span {
  font-size: 14px;
  font-weight: bold;
}
