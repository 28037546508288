/* #wrapper */
#wrapper {
  position: relative;
  z-index: 1;
  margin: 104px auto 0;
  min-height: 500px;
  width: 100%;
  max-width: $content-width;
}

#wrapper-960 {
  position: relative;
  z-index: 1;
  margin: 104px auto 80px;
  min-height: 500px;
  width: 100%;
  max-width: $max-width;
}

#wrapper-top {
  position: relative;
  z-index: 1;
  margin: 0 auto 0;
  min-height: 500px;
  width: 100%;
  max-width: $content-width;
}

/* Content */
#content {
  margin: 0 auto;
  padding: 0 16px 40px;
  /* padding-bottom:1px; */
}

#content-about {
  margin: 0 auto;
  padding: 80px 16px 40px;
}

/* 枠テンプレート
**************************************************/
.mnc_wrap_requirements {
  display: block;
  overflow: hidden;
  margin: 20px auto 20px;
  padding: 20px 20px 0;
  border-radius: 10px;
  border: 1px solid #c2c2c2;
  text-align: center;
  width: 600px;
  background: #fff;
}
.mc_wrap_finish {
  margin-bottom: 15px;
  min-height: 100px;
  border-radius: 4px;
}
.mc_wrap_finish a.mc_a {
  color: #0672b8;
  text-decoration: underline;
}
.mc_wrap_finish h3 {
  color: $success-color;
  font-size: 16px;
  font-weight: bold;
}
.mc_wrap_wait {
  margin-bottom: 15px;
  padding: 15px 20px 6px;
  border-radius: 4px;
  background: #ffebed;
  margin-top: 15px;
}

.mc_msg_check {
  color: #de4057;
  font-size: 10px;
  margin-bottom: 0.8rem;
}

@media (max-width: 480px) {
  #wrapper {
    margin: 88px auto 0;
  }
  #content-about {
    margin: 0 auto;
    padding: 64px 16px 40px;
  }
}
