/*
# grid
 */

// variables
// --------------------
.c-flex {
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  // Modifier
  // --------------------
  &--top {
    align-items: flex-start;
  }

  &--middle {
    align-items: center;
  }

  &--bottom {
    align-items: flex-end;
  }

  &--stretch {
    align-items: stretch;
  }

  &--baseline {
    align-items: baseline;
  }

  &--left {
    justify-content: flex-start;
  }

  &--center {
    justify-content: center;
  }

  &--right {
    justify-content: flex-end;
  }

  &--between {
    justify-content: space-between;
  }

  &--reverse {
    flex-direction: row-reverse;
  }
}

// Modifier - gutters
// --------------------
.c-flex--gutters > .c-flex__col {
  margin-left: $var-col-gutters;

  &:first-child {
    margin-left: 0;
  }

  @for $i from 1 through $var-col {
    &--#{$i + of + $var-col} {
      width: $var-col-width-gutters * $i - $var-col-gutters;
    }
  }
}

// Modifier - query
// --------------------
.c-flex--query {
  > .c-flex__col {
    width: 100%;
    display: block;
  }
}

.c-flex--2to4 {
  > .c-flex__col {
    width: 50% !important;
  }
}

.c-flex--2to4.c-flex--gutters {
  > .c-flex__col {
    margin: 0;
    width: $var-col-width-gutters * 6 - 4% !important;
  }
}

.c-flex--query.c-flex--gutters > .c-flex__col {
  margin-left: 0;
}

// Element
// --------------------
.c-flex__col {
  box-sizing: border-box;
  -webkit-flex-shrink: 1;
  flex-shrink: 0;

  &--full {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
  }

  @for $i from 1 through $var-col {
    &--#{$i + of + $var-col} {
      width: $var-col-width * $i;
    }
  }
}

// PC only
// --------------------
@media (min-width: 640px) {
  .c-flex--2to4 {
    > .c-flex__col {
      width: $var-col-width * 3 !important;
    }
  }

  .c-flex--2to4.c-flex--gutters {
    > .c-flex__col {
      width: $var-col-width-gutters * 3 - $var-col-gutters !important;
    }
  }
}

@media (min-width: $var-breakpoint) {
  .c-flex--query {
    display: flex;
    flex-wrap: wrap;

    > .c-flex__col {
      box-sizing: border-box;
      -webkit-flex-shrink: 1;
      flex-shrink: 0;
      margin-bottom: 0;
      display: inline-block;
      width: auto;

      @for $i from 1 through $var-col {
        &--#{$i + of + $var-col} {
          width: $var-col-width * $i !important;
        }
      }
    }
  }

  .c-flex--query.c-flex--gutters > .c-flex__col {
    margin-left: $var-col-gutters;

    &:first-child {
      margin: 0;
    }

    @for $i from 1 through $var-col {
      &--#{$i + of + $var-col} {
        width: $var-col-width-gutters * $i - $var-col-gutters !important;
      }
    }
  }

  .c-flex--query.c-flex--gutters.c-flex--reverse > .c-flex__col {
    margin-left: 0;
    margin-right: $var-col-gutters;

    &:first-child {
      margin: 0;
    }

    @for $i from 1 through $var-col {
      &--#{$i + of + $var-col} {
        width: $var-col-width-gutters * $i - $var-col-gutters !important;
      }
    }
  }
}
