/*投稿媒体アイコン*/
.mType > li.blog {
  background: url(/assets/images/ico_blog.png);
}
.mType > li.twitter {
  background: url(/assets/images/ico_twitter.png);
}
.mType > li.facebook {
  background: url(/assets/images/ico_facebook.png);
}
.mType > li.instagram {
  background: url(/assets/images/ico_instagram.png);
}
.mType > li.ios {
  background: url(/assets/images/iconSprite_deviceType_30.png) 0 0;
}
.mType > li.android {
  background: url(/assets/images/iconSprite_deviceType_30.png) 0 -30px;
}

.mType .icon_feed,
.mType .fa-rss-square,
.mType .icon_twitter,
.mType .fa-twitter-square,
.mType .icon_facebook,
.mType .fa-facebook-square,
.mType .icon_instagram,
.mType .fa-instagram,
.mType .icon_apple,
.mType .icon_android,
.mType .fa-apple,
.mType .fa-android {
  font-size: 20px;
}

.icon_feed,
.fa-rss-square {
  color: #b64342;
}
.icon_twitter,
.fa-twitter-square {
  color: #55acee;
}
.icon_facebook,
.fa-facebook-square {
  color: #3b5998;
}
.icon_instagram,
.fa-instagram {
  color: #666;
}
.icon_apple,
.fa-apple {
  color: #000;
}
.icon_android,
.fa-android {
  color: #a4c639;
}
