/*
==============================================
expandOpen
==============================================
*/

.expandOpen {
  animation-name: expandOpen;
  -webkit-animation-name: expandOpen;

  animation-duration: 1.2s;
  -webkit-animation-duration: 1.2s;

  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;

  visibility: visible !important;
}

@keyframes expandOpen {
  0% {
    transform: scale(1.8);
  }
  50% {
    transform: scale(0.95);
  }
  80% {
    transform: scale(1.05);
  }
  90% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes expandOpen {
  0% {
    -webkit-transform: scale(1.8);
  }
  50% {
    -webkit-transform: scale(0.95);
  }
  80% {
    -webkit-transform: scale(1.05);
  }
  90% {
    -webkit-transform: scale(0.98);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
