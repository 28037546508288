/* error存在通知 */
.errNotice {
  margin-bottom: 20px;
  padding-left: 25px;
  background: url(/assets/images/ico_err_large.png) 0 3px no-repeat;
  color: $caution-color;
  font-size: 18px;
  font-weight: bold;
}

/* 個別errorメッセージ */
.errWrap {
  position: relative;
  /*display: none;*/
}
.errWrap .inner {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 5px 0 0;
  padding: 8px 8px 1px;
  width: auto;
  border-radius: 4px;
  background: #ffebed;
  box-shadow: 0 1px 10px #ccc;
}
.errWrap .inner:before {
  position: absolute;
  bottom: -10px;
  left: 25px;
  width: auto;
  content: url(/assets/images/popupArrowB_20x8.png);
}
.err {
  margin: 0 0 5px;
  padding-left: 16px !important;
  background: url(/assets/images/ico_err.png) 0 -1px no-repeat;
  color: $caution-color;
  text-align: left;
  font-size: 14px;
  line-height: 1.2;
}
.errbgStyle {
  background: #ffebed;
}
.loginErr {
  margin-bottom: 20px;
  padding-left: 25px;
  background: url(/assets/images/ico_err_large.png) 0 3px no-repeat;
  color: $caution-color;
  font-size: 18px;
  font-weight: bold;
}

/* error
**************************************************/
.mc_mess {
  margin: 0 0 20px 0;
  color: #333;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.mc_mess > em {
  display: block;
  color: $caution-color;
  font-size: 20px;
}
.mc_submess {
  margin-bottom: 60px;
  color: #666;
  text-align: center;
  font-size: 18px;
}
.mess {
  padding: 10px 60px 14px;
  color: #333;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  border-radius: 5px;
}
.mess > em {
  display: block;
  color: $caution-color;
  font-size: 20px;
}
.submess {
  color: #666;
  text-align: center;
  font-size: 14px;
  padding-bottom: 22px;
}
#container.w720 {
  margin: 0 auto;
}
#container.w610 {
  margin: 0 auto;
}
#container.w610 > .mc_box,
#container.w720 > .mc_box {
  margin: 0;
  padding-bottom: 1px;
}
.container_box {
  background-color: #fff;
}
.link_area {
  width: 240px;
  margin: 0 auto;
  display: block;
  height: 20px;
  padding-top: 6px;
}
.link_area li {
  float: left;
  margin-right: 30px;
}
.link_area li:last-child {
  margin-right: 0px;
}
