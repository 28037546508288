/* new-year
--------------------------------------------------------- */
.info_new_year {
  font-size: 14px;
  padding: 10px;
  border: 1px solid #e40000;
  background: #fdf7f7;
  margin-bottom: 16px;
  color: #d6221c;
}
.info_new_year_title {
  font-size: 14px;
  font-weight: bold;
}
