.c-title {
  font-weight: 800;
}

.c-title--h3 {
  font-size: 18px;
}

.c-title--h4 {
  font-size: 16px;
}

.c-title--h5 {
  font-size: 14px;
  padding: 8px;
  background: $info-color;
  margin-bottom: 8px;
}

.c-title--h2 {
  text-align: center;
  padding: 16px;
  margin-bottom: 20px;
}

.c-title__sub {
  font-size: 12px;
  margin: 0;
  display: block;
}

.c-title__main {
  font-size: 22px;
  font-weight: 800;
  display: inline-block;
  position: relative;

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 2px;
    background: $primary-color;
    position: absolute;
    bottom: -8px;
    left: 0;
    z-index: 10;
  }
}