@charset "utf-8";
/* common.css */

// ---------------------------------------------------------------
//  Core (変数の管理)
// ---------------------------------------------------------------
@import "../core/variables";

// ---------------------------------------------------------------
//  Foundation (共通のスタイル)
// ---------------------------------------------------------------
@import "../foundation/default";
@import "../foundation/animation";

// ---------------------------------------------------------------
//  Layout (基本レイアウトスタイル)
// ---------------------------------------------------------------
@import "../layout/wrapper";
@import "../layout/header";
@import "../layout/footer";

// ---------------------------------------------------------------
//  Object/component (汎用Componentスタイル)
// ---------------------------------------------------------------
@import "../object/component/errors";
@import "../object/component/button";
@import "../object/component/banner";
@import "../object/component/breadCrumbs";
@import "../object/component/mc_box";
@import "../object/component/mc_hx";
@import "../object/component/mc_pager";
@import "../object/component/mc_searchResult";
@import "../object/component/mc_a";
@import "../object/component/mc_table";
@import "../object/component/mc_list";
@import "../object/component/mc_p";
@import "../object/component/mc_guide";
@import "../object/component/modal";
@import "../object/component/eType";
@import "../object/component/mType";
@import "../object/component/info_new_year";
@import "../object/component/drawer";
@import "../object/component/flex";
@import "../object/component/panel";
@import "../object/component/container";
@import "../object/component/title";

// ---------------------------------------------------------------
//  Object/project (プロジェクトスタイル)
// ---------------------------------------------------------------
@import "../object/project/safetyGrade";
@import "../object/project/backdrop";
@import "../object/project/form";
@import "../object/project/topEnquete";
@import "../object/project/userSummary";
@import "../object/project/recommended";
@import "../object/project/reviews";
@import "../object/project/special_shereBox";
@import "../object/project/captcha";
@import "../object/project/eventList";

// ---------------------------------------------------------------
//  Object/project/category (カテゴリ別スタイル)
// ---------------------------------------------------------------
@import "../object/project/category/signup";

// ---------------------------------------------------------------
//  Object/utility (汎用スタイル)
// ---------------------------------------------------------------
@import "../object/utility/style";
