/* Anchor */
.mc_a {
  padding: 5px 5px 5px 15px;
  background: url(/assets/images/ico_linkArrowR.png) 0 center no-repeat;
  font-size: 14px;
}

/* red */
.mc_a.red {
  color: $caution-color;
}

/* orange */
.mc_a.orange {
  color: #fd871a;
}

/* lime */
.mc_a.lime {
  color: #bfda00;
}

/* green */
.mc_a.green {
  color: #5eb31e;
}

.mc_a.success {
  color: $success-color;
}

a.mc_a_inline:link,
a.mc_a_inline:visited {
  color: #5eb31e;
  text-decoration: underline;
}
a.mc_a_inline:hover {
  color: #5eb31e;
  text-decoration: none;
}
