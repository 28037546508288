/* mc_guide */
.mc_guide {
  margin-bottom: 15px;
  padding: 20px 20px 1px;
  min-height: 100px;
  border-radius: 4px;
  background: #ffebed;
}
.mc_guide h3 {
  color: $caution-color;
  font-size: 16px;
  font-weight: bold;
}
.mc_guide.basic {
  background: $info-color;
}
.mc_guide.basic h3 {
  color: #333;
}
