/* backdrop */
#backdrop {
  position: absolute;
  top: 228px;
  z-index: 1;
  width: 100%;
  height: 300px;
  text-align: center;
}
#backdrop > span {
  display: block;
  width: 100%;
  height: 300px;
  /* box-shadow:0 1px 8px #ccc inset; */
}
#backdrop.type2 {
  background: url(/assets/images/backdrop2_repeat.jpg) center center repeat-x;
}
#backdrop.type2 > span {
  background: url(/assets/images/backdrop2.jpg) center center no-repeat;
}
