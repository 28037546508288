/* safetyGrade */
#safetyGrade {
  position: relative;
  display: none;
  margin: 5px 0 0;
  padding: 8px 8px 1px;
  width: auto;
  border-radius: 4px;
  background: #ffebed;
}
#safetyGrade:before {
  position: absolute;
  top: -13px;
  left: 25px;
  width: auto;
  content: url(/assets/images/popupArrowT_20x8.png);
}
#safetyGrade .inner {
  margin-bottom: 8px;
}
#safetyGrade .mnc_a {
  color: #f65068;
}
#safetyGrade .valid {
  margin: 0 0 5px !important;
  padding-left: 16px;
  background: url(/assets/images/ico_valid.png) 0 -1px no-repeat;
  font-size: 12px;
  line-height: 1.2;
}
