/*イベントタイプ　アイコン*/
.eType {
  margin-right: 1px;
  display: inline-block;
  font-size: 10px;
  border-radius: 2px;
  background: $base-color-border;
  padding: 2px 4px;
}

.eType.wchance {
  background: #fd871a;
  color: #fff;
}

//.eType .icon_monitor,
//.eType .icon_subject,
//.eType .icon_goout,
//.eType .icon_shopping,
//.eType .icon_question,
//.eType .icon_join,
//.eType .icon_vote,
//.eType .icon_photo,
//.eType .icon_point,
//.eType .icon_lot,
//.eType .icon_click,
//.eType .icon_reduce,
//.eType .icon_ec {
//  padding-right: 5px;
//}
//
//.eType.monitor {
//  /* モニター */
//  background-color: $success-color;
//}
//.eType.goOut {
//  /* お出かけ */
//  background-color: #ef457a;
//}
//.eType.theme {
//  /* お題 */
//  background-color: #a36900;
//}
//.eType.purchase {
//  /* 購入 */
//  background-color: #5db090;
//}
//.eType.enquete {
//  /* アンケート */
//  background-color: #d57900;
//}
//.eType.conversion {
//  /* 参加でGET */
//  background-color: #ff84c7;
//}
//.eType.imgUpload {
//  /* 写真 */
//  background-color: #696969;
//}
//.eType.ranking {
//  /* 投票 */
//  background-color: #f0b001;
//}
//.eType.point {
//  /* ポイント */
//  background-color: #ba8d26;
//}
//.eType.speedlot {
//  /* くじ */
//  background-color: #e95504;
//}
//.eType.reductive {
//  /* 還元モニター */
//  background-color: #5db090;
//}
//.eType.click {
//  /* 広めてPt */
//  background-color: #209ec9;
//}
