/*#topEnquete*/
#topEnquete {
  background: #fff;
  margin: 0 0 20px;
  padding: 10px;
  width: 210px;
  background: url(/assets/images/bg_enquete_repeat.png);
}
#topEnquete .enqueteWrap,
#topEnquete .pointGetWrap {
  background: #fff;
  margin: 0 0 15px;
  padding: 10px;
  box-shadow: 0 1px 10px #ccc;
}
#topEnquete .enqueteInner,
#topEnquete .pointGetInner {
  border-top: 2px #ded4bc dashed;
  border-bottom: 2px #ded4bc dashed;
  padding: 15px 5px;
}
#topEnquete .enqueteQuestion {
  font-size: 18px;
  padding-bottom: 15px;
  border-bottom: 1px #ece8dd solid;
  margin-bottom: 15px;
}
#topEnquete .enqueteQuestion:before {
  content: "Q.";
  font-size: 14px;
  color: #ccc3ad;
}
#topEnquete .answerWrap {
  margin-bottom: 15px;
}
#topEnquete .enqueteAnswer {
  margin: 0 5px 5px;
}
#topEnquete .pointGetInner {
  height: 104px;
}
#topEnquete .imgWrap {
  display: block;
  height: 100px;
  width: 140px;
  margin-left: auto;
  margin-right: auto;
  background: url("/assets/images/img_enqPtGet.png") no-repeat center;
  background-size: contain;
  visibility: hidden;
}
#topEnquete .imgWrap img {
}
