.c-drawer {
  display: none;
}

.c-drawer-inner h3 {
  font-size: 16px;
  font-weight: bold;
  border-bottom: solid 1px $base-color-border;
  padding: 16px 0 0;
  margin-bottom: 8px;
}

.c-drawer-navi li a {
  display: block;
  padding: 4px 0;
  font-size: 14px;
  background: url(/assets/images/ico_linkArrowR.png) 100% 9px no-repeat;
}


.c-drawer-navi li a span {
  display: inline-block;
  padding: 0 4px 1px;
  color: #fff;
  background: $primary-color;
  font-size: 10px;
  border-radius: 8px;
}
.c-userInfo__nickname {
  font-size: 16px;
}
.c-userInfo__nickname span {
  font-size: 12px;
}
.c-userInfo__point {
  font-size: 14px;
}

@media (max-width: 480px) {
  .c-drawer {
    display: block;
    position: fixed;
    top: 56px;
    z-index: 1000;
    width: 80vw;
    height: 100%;
    background: #fff;
    visibility: hidden;
  }

  .c-drawer.is-right {
    right: -80vw;
    top: 56px;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    -webkit-transition-property: box-shadow, visibility, -webkit-transform;
    transition-property: box-shadow, visibility, -webkit-transform;
    transition-property: box-shadow, transform, visibility;
    transition-property: box-shadow, transform, visibility, -webkit-transform;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .c-drawer.is-left {
    left: -80vw;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    -webkit-transition-property: box-shadow, visibility, -webkit-transform;
    transition-property: box-shadow, visibility, -webkit-transform;
    transition-property: box-shadow, transform, visibility;
    transition-property: box-shadow, transform, visibility, -webkit-transform;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  .c-drawer.is-open {
    visibility: visible;
    height: 100%;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.16);
  }

  .c-drawer.is-left.is-open {
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    -webkit-transform: translate3d(80vw, 0, 0);
    transform: translate3d(80vw, 0, 0);
  }

  .c-drawer.is-right.is-open {
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    -webkit-transform: translate3d(-80vw, 0, 0);
    transform: translate3d(-80vw, 0, 0);
  }

  .c-drawer-inner {
    padding: 8px;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .c-drawer-overlay {
    position: fixed;
    top: 0;
    z-index: 900;
    display: none;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
  }

  .c-drawer-overlay.is-fadein {
    display: block;
    opacity: 0.2;
  }
  .c-drawer-btn {
    background: #fff;
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 0.8rem;
    right: 1rem;
    z-index: 1200;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    overflow: hidden;
  }

  .c-drawer-btn.is-right {
    right: 1rem;
  }

  .c-drawer-btn.is-left {
    left: 1rem;
  }

  .c-drawer-btn__icon {
    position: relative;
    display: block;
    width: 18px;
    height: 1px;
    border-radius: 3px;
    background: #222;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
  }

  .c-drawer-btn__icon:before,
  .c-drawer-btn__icon:after {
    position: absolute;
    display: block;
    width: 18px;
    height: 1px;
    border-radius: 3px;
    background: #222;
    content: "";
    -webkit-transition: all 0.3s !important;
    transition: all 0.3s !important;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  .c-drawer-btn__icon:before {
    -webkit-transform: translateY(0.4rem);
    transform: translateY(0.4rem);
  }

  .c-drawer-btn__icon:after {
    -webkit-transform: translateY(-0.4rem);
    transform: translateY(-0.4rem);
  }

  .is-open.c-drawer-btn .c-drawer-btn__icon {
    background: #fff;
  }

  .is-open.c-drawer-btn .c-drawer-btn__icon:before {
    background: #222;
    -webkit-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }

  .is-open.c-drawer-btn .c-drawer-btn__icon:after {
    background: #222;
    -webkit-transform: translateY(0) rotate(45deg);
    transform: translateY(0) rotate(45deg);
  }
}
