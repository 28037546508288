.common_bnrArea {
  width: 100%;
  margin: 0 auto 20px;
  text-align: center;
}
.common_bnrArea a {
  display: inline-block;
}
.common_bnrArea img {
  width: 100%;
}

/* バナーエリア */
#bannerArea .banner_list {
  width: 300px;
}
#bannerArea .banner_list li {
  width: 300px;
  margin-bottom: 20px;
}
#bannerArea .banner_list_top {
  width: 230px;
}
#bannerArea .banner_list_top li {
  width: 230px;
  margin-bottom: 10px;
}
#bannerArea .banner_list_top li:last-child {
  width: 230px;
  margin-bottom: 40px;
}

/* .bannerContents
**************************************************/

.bannerContents {
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
}
.bannerContents a {
  display: block;
}
.bannerContents__inner {
  position: relative;
}
.bannerContents__text {
  text-align: center;
  font-size: 14px;
}
.bannerContents__piece {
  width: 100%;
}
.bannerContents__list {
  display: inline-block;
  float: left;
}
.bannerContents__list:nth-child(even) {
  float: right;
}

.bannerContents__posBox {
  background: #333;
  padding: 6px;
  width: 80px;
  text-align: center;
  position: absolute;
  top: 10px;
  left: 10px;
}
.bannerContents__posBox p {
  color: #fff;
  font-size: 16px;
  display: inline-block;
}
.bannerContents__posBox__num-text {
  font-size: 14px;
}
