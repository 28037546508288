.p-eventList {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: nowrap;
  padding: 4px;

  &__item {
    display: inline-block;
    white-space: normal;
    width: 144px;
    padding: 4px;
    position: relative;

    a {
      text-decoration: none;
      display: block;

      &:hover {
        opacity: .8 !important;
        text-decoration: none !important;
      }
    }

  }

  &__image {
    img {
      width: 132px;
      height: 132px;
      display: block;
      margin: 0 auto;
    }
  }

  &__title {
    font-size: 12px;
    height: 40px;
    overflow: hidden;
  }

  &__num {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 11px;
    display: block;
    width: 22px;
    height: 22px;
    background: #ddd;
    font-size: 10px;
    text-align: center;
    line-height: 22px;

    &.one {
      background: #D9B340;
      color: #fff;
    }

    &.two {
      background: #6F7B83;
      color: #fff;
    }

    &.three {
      background: #A15326;
      color: #fff;
    }
  }

  &__text {
    font-size: 12px;
    span {
      font-size: 16px;
      color: $caution-color;
      font-weight: bold;
    }
  }
}