.c-container {
  max-width: $max-width;
  background: #fff;
  padding: 8px 16px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);
}

.c-container--query {
  max-width: $max-width;
  background: #fff;
  padding: 8px 16px;
}

.c-container--banner {
  max-width: 640px;
  padding: 24px 16px;
  margin: 0 auto;
}

.c-container--noBg {
  max-width: $max-width;
  padding: 24px 0;
}

.c-container--attention {
  background: #ffebed;
  color: $caution-color;
}

.c-container--title {
  max-width: $max-width;
  padding: 16px 8px 8px;
}

@media (max-width: 828px) {
  .c-container--query {
    padding: 0 16px;
  }
}