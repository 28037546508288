/* #header */
#header {
  position: fixed;
  z-index: 1200;
  margin-bottom: 20px;
  width: 100%;
  height: 80px;
  top: 0;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);
}

.header {
  width: auto;
  height: 80px;
  padding: 0 16px;
  max-width: $max-width;
  margin: 0 auto;
  position: relative;
}

.header__sitecopy {
  position: absolute;
  top: 0;
  left: 16px;
  font-size: 9px;
}

.header__sitecopy h1 {
  display: inline;
}

.header__logo {
  font-size: 24px;
  text-align: center;
  height: 80px;
  display: flex;
  align-items: center;
}

.header__nav {
  position: absolute;
  top: 0;
  right: 16px;
  display: flex;
  align-items: center;
  height: 80px;
}

.header__nav .indiv_menu {
  display: inline-block;
  position: relative;
}

.header__nav .indiv_menu a {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin: 0 4px;
  border-radius: 50%;
  background: $base-color-border;
  text-align: center;
  line-height: 36px;
}

.header__nav .indiv_menu a:hover {
  text-decoration: none;
}

.header__navIcon {
  font-size: 18px;
}

.header__userBtn {
  display: flex;
  align-items: center;
  width: 132px;
  height: 44px;
  padding: 0 8px;
  border-radius: 22px;
  border: solid 1px $base-color-border;
  background-color: #ffffff;
  margin-left: 16px;
  cursor: pointer;

  img {
    margin-right: 8px;
  }
}

.header__userBtn:hover {
  background: $base-color-border;
}

.header__userBtn .more_arrow {
  right: 16px;
}

.header__usermenu {
  position: relative;
}

.header__usermenu .my_info_pulldownmenu,
.usermenu .indiv_info .indiv_info_pulldownmenu {
  position: absolute;
  display: none;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);
  top: 64px;
  right: 0;
  background: #fff;
  z-index: 200;
  width: 280px;
  height: calc(100vh - 120px);
  overflow: auto;
  padding: 16px;
  -webkit-font-smoothing: antialiased;
}
.usermenu .indiv_info .indiv_info_pulldownmenu {
  left: -115px;
  min-width: 420px;
}
.header__usermenu .my_info_pulldownmenu .menu_arrow,
.usermenu .indiv_info .indiv_info_pulldownmenu .menu_arrow {
  background: url(/assets/images/arrow_head_menu.png) no-repeat;
  z-index: 200;
  position: absolute;
  top: -9px;
  left: 22px;
  width: 12px;
  height: 9px;
}

.header__nav .menu_badge {
  position: absolute;
  line-height: 1;
  top: 0;
  right: 0;
}
.header__nav .menu_badge span {
  background: #222;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 1px 2px;
  font-size: 9px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
}
.header__nav .menu_tooltip {
  display: none;
  position: absolute;
  width: 80px;
  border: 2px solid $base-color-border;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #fffceb;
  left: 50%;
  margin-left: -40px;
  padding: 4px 0;
  text-align: center;
  top: 40px;
  z-index: 100;
  font-size: 10px;
}
.header__nav .menu_tooltip:before {
  content: "";
  border: 6px solid transparent;
  border-bottom: 6px solid $base-color-border;
  position: absolute;
  right: 46%;
  bottom: 26px;
}

.header__login {
  display: none;
}

@media (max-width: 480px) {
  #header {
    height: 56px;
  }

  .header {
    height: 56px;
  }
  .header__sitecopy {
    display: none;
  }
  .header__logo {
    justify-content: center;
    height: 56px;
    font-size: 20px;
  }
  .header__nav {
    display: none;
  }
  .header__login {
    display: block;
    position: absolute;
    top: 14px;
    right: 16px;
    font-size: 9px;
    text-align: center;
    line-height: 1.3;
  }
  .header__login .fas {
    font-size: 16px;
  }
}

.header--narisumashi {
  background-color: $primary-color;
}

.more_arrow {
  position: absolute;
  border-color: #909090 transparent;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  width: 0;
  height: 0;
  top: 50%;
  margin-left: 3px;
  margin-top: -1px;
}
