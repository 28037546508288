/*userSummary*/
#userSummary {
  background: #fff;
  margin: 0 0 40px;
  padding: 8px;
  width: 230px;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid $base-color-border;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
/*userSummary #userInfo.noLogin*/
#userSummary #userInfo.noLogin .userWrap {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: #efefef 1px solid;
}
#userSummary #userInfo.noLogin #userImg {
  width: 40px;
  float: left;
}
#userSummary #userInfo.noLogin #userImg img {
  max-width: 40px;
  max-height: 40px;
}
#userSummary #userInfo.noLogin #user {
  width: 160px;
  float: left;
  margin-left: 10px;
  line-height: 40px;
  font-size: 12px;
}
#userSummary #userInfo.noLogin .btnWrap {
  text-align: center;
}
/*userSummary #userInfo.Login*/
#userSummary #userInfo.login {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: #efefef 1px solid;
}
#userSummary #userInfo.login #userImg {
  width: 100px;
  margin: 0 auto;
}
#userSummary #userInfo.login #userImg img {
  max-width: 100px;
  max-height: 100px;
}
#userSummary #userInfo.login #userName {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}
#userSummary #userInfo.login #pointNum {
  text-align: center;
  font-size: 12px;
}
#userSummary #userInfo.login #pointNum span.icon_point {
  color: #888;
  margin-right: 4px;
}
#userSummary #userInfo.login #pointNum span {
  margin-left: 4px;
  color: #666;
}
#userSummary #profile h3 {
  position: relative;
  margin-bottom: 5px;
}
#userSummary #profile h3 em {
  font-size: 12px;
}
#userSummary #profile h3 span {
  position: absolute;
  top: 0px;
  right: 0;
  font-size: 12px;
  background: #999999;
  display: inline-block;
  color: #fff;
  padding: 1px 5px;
  border-radius: 5px;
}
#userSummary #profile h3 span.comp {
  background: #f45757;
}
#userSummary #mission {
  position: relative;
  background: #ebebeb;
  padding: 10px 0 1px;
}
#userSummary #mission li {
  display: block;
  position: relative;
  width: 200px;
  height: 30px;
  margin: 0 auto 10px;
  background: #f8f8f8;
  border-radius: 5px;
  border-bottom: 2px solid #d0d0d0;
  border-right: 2px solid #d0d0d0;
}
#userSummary #mission li a {
  display: block;
  width: 200px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  text-indent: 30px;
  text-decoration: none;
}
#userSummary #mission li a.profileEmail {
  background: url(/assets/images/iconSprite_profile.png) -5px -5px no-repeat;
}
#userSummary #mission li a.profileAddress {
  background: url(/assets/images/iconSprite_profile.png) -5px -45px no-repeat;
}
#userSummary #mission li a.profileOther {
  background: url(/assets/images/iconSprite_profile.png) -5px -85px no-repeat;
}
#userSummary #mission li a .checkDone {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 10px;
  text-indent: -1000em;
  background: url(/assets/images/iconSprite_profile.png) -5px -125px no-repeat;
}
#userSummary #mission .arrow_userMiniProfile {
  position: absolute;
  left: 50%;
  margin-left: -5px;
  bottom: -5px;
}
#userSummary #profile .pointGet {
  background: #f45757 url(/assets/images/arrow_userMiniProfile.png) center 0
    no-repeat;
  color: #fff;
  text-align: center;
  font-size: 14px;
}
#userSummary #profile .pointGet.comp {
  background: #999 url(/assets/images/arrow_userMiniProfile.png) center 0 no-repeat;
}

/* mission area by user summary */
#userSummary #missionInfo {
  padding: 5px 5px 10px 13px;
}
#userSummary #missionInfo .area_image {
  float: left;
  width: 50px;
  margin-right: 20px;
  position: relative;
}
#userSummary #missionInfo .area_image .mission_badge span {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  top: -8px;
  left: -8px;
}
#userSummary #missionInfo .area_image .mission_badge {
  -moz-animation: anime_mission_mark 1s ease-out 10;
  -webkit-animation: anime_mission_mark 1s ease-out 10;
  animation: anime_mission_mark 1s ease-out 10;
  -moz-transform-origin: 10% 50%;
  -webkit-transform-origin: 10% 50%;
  transform-origin: 10% 50%;
}
@-ms-keyframes anime_mission_mark {
  0% {
    -ms-transform: translateY(30%) scaleX(0.2) scaleY(0.2);
  }
  30% {
    -ms-transform: translateY(-30%) scaleX(0.5) scaleY(0.5);
  }
  40% {
    -ms-transform: translateY(2%) scaleX(0.5);
  }
  50% {
    -ms-transform: translateY(0%) scaleX(1.1);
  }
  60% {
    -ms-transform: translateY(0%) scaleX(0.9);
  }
  70% {
    -ms-transform: translateY(0%) scaleX(1.05);
  }
  80% {
    -ms-transform: translateY(0%) scaleX(0.95);
  }
  90% {
    -ms-transform: translateY(0%) scaleX(1.02);
  }
  100% {
    -ms-transform: translateY(0%) scaleX(1);
  }
}
@-moz-keyframes anime_mission_mark {
  0% {
    -moz-transform: translateY(30%) scaleX(0.2) scaleY(0.2);
  }
  30% {
    -moz-transform: translateY(-30%) scaleX(0.5) scaleY(0.5);
  }
  40% {
    -moz-transform: translateY(2%) scaleX(0.5);
  }
  50% {
    -moz-transform: translateY(0%) scaleX(1.1);
  }
  60% {
    -moz-transform: translateY(0%) scaleX(0.9);
  }
  70% {
    -moz-transform: translateY(0%) scaleX(1.05);
  }
  80% {
    -moz-transform: translateY(0%) scaleX(0.95);
  }
  90% {
    -moz-transform: translateY(0%) scaleX(1.02);
  }
  100% {
    -moz-transform: translateY(0%) scaleX(1);
  }
}
@-webkit-keyframes anime_mission_mark {
  0% {
    -webkit-transform: translateY(30%) scaleX(0.2) scaleY(0.2);
  }
  30% {
    -webkit-transform: translateY(-30%) scaleX(0.5) scaleY(0.5);
  }
  40% {
    -webkit-transform: translateY(2%) scaleX(0.5);
  }
  50% {
    -webkit-transform: translateY(0%) scaleX(1.1);
  }
  60% {
    -webkit-transform: translateY(0%) scaleX(0.9);
  }
  70% {
    -webkit-transform: translateY(0%) scaleX(1.05);
  }
  80% {
    -webkit-transform: translateY(0%) scaleX(0.95);
  }
  90% {
    -webkit-transform: translateY(0%) scaleX(1.02);
  }
  100% {
    -webkit-transform: translateY(0%) scaleX(1);
  }
}
@keyframes anime_mission_mark {
  0% {
    transform: translateY(30%) scaleX(0.2) scaleY(0.2);
  }
  30% {
    transform: translateY(-30%) scaleX(0.5) scaleY(0.5);
  }
  40% {
    transform: translateY(2%) scaleX(0.5);
  }
  50% {
    transform: translateY(0%) scaleX(1.1);
  }
  60% {
    transform: translateY(0%) scaleX(0.9);
  }
  70% {
    transform: translateY(0%) scaleX(1.05);
  }
  80% {
    transform: translateY(0%) scaleX(0.95);
  }
  90% {
    transform: translateY(0%) scaleX(1.02);
  }
  100% {
    transform: translateY(0%) scaleX(1);
  }
}

#userSummary #missionInfo .area_desc {
  float: left;
  width: 180px;
  padding-top: 5px;
}
#userSummary #missionInfo .desc_clear p {
  font-size: 10px;
}
#userSummary #missionInfo .desc_clear p.desc_have {
  float: left;
}
#userSummary #missionInfo .desc_clear p.desc_number {
  float: right;
}
#userSummary #missionInfo p.desc_linktext {
  text-align: right;
  padding-top: 5px;
}
#userSummary #missionInfo p.desc_linktext a {
  color: $success-color;
  text-decoration: none;
}
.item_per {
  width: 160px;
  height: 5px;
  margin: 0 0 0 0;
  border: 1px solid #767676;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.5, rgb(207, 207, 208)),
    color-stop(0.51, rgb(175, 175, 176)),
    to(#afafb0)
  );
  background: -moz-linear-gradient(
    center bottom,
    rgb(207, 207, 208) 50%,
    rgb(175, 175, 176) 51%
  );
  background-color: #cfcfd0;
}
#mypage #userSummary .item_per {
  width: 180px;
}
.top #userSummary #missionInfo {
  padding: 5px 5px 5px 2px;
}
.top #userSummary #missionInfo .area_image {
  width: 36px;
  margin-right: 10px;
}
.top #userSummary #missionInfo .area_desc {
  width: 156px;
  padding-top: 0;
}
.top .item_per {
  width: 156px;
}
.per10,
.per20,
.per30,
.per40 {
  border-right: 1px solid #767676;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.9, rgb(254, 234, 26)),
    color-stop(0.91, rgb(252, 210, 17)),
    to(#fcd211)
  );
  background: -moz-linear-gradient(
    center bottom,
    rgb(254, 234, 26) 90%,
    rgb(252, 210, 17) 91%
  );
  background-color: #fcd211;
}
.per50,
.per60,
.per70 {
  border-right: 1px solid #767676;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.9, rgb(211, 55, 55)),
    color-stop(0.91, rgb(162, 36, 36)),
    to(#d33737)
  );
  background: -moz-linear-gradient(
    center bottom,
    rgb(211, 55, 55) 90%,
    rgb(162, 36, 36) 91%
  );
  background-color: #d33737;
}
.per80,
.per90,
.per100 {
  border-right: 1px solid #767676;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.9, rgb(78, 223, 78)),
    color-stop(0.91, rgb(51, 188, 51)),
    to(#33bc33)
  );
  background: -moz-linear-gradient(
    center bottom,
    rgb(254, 234, 26) 90%,
    rgb(252, 210, 17) 91%
  );
  background-color: #4edf4e;
}
.per00 {
  width: 0%;
}
.per10 {
  width: 10%;
}
.per20 {
  width: 20%;
}
.per30 {
  width: 30%;
}
.per40 {
  width: 40%;
}
.per50 {
  width: 50%;
}
.per60 {
  width: 60%;
}
.per70 {
  width: 70%;
}
.per80 {
  width: 80%;
}
.per90 {
  width: 90%;
}
.per100 {
  width: 100%;
}
