/* CAPTCHA */
#captcha #content .inputinner {
  max-width:  640px;
  margin: 0 auto;
  padding: 30px 20px 10px 20px;
  border: 1px solid $base-color-border;
  background: #fff;
  border-radius: 10px;
  margin-top: 90px;
}
#captcha #content p.pass_initial {
  text-align: center;
  margin-top: 6px;
}
#captcha #content p.pass_initial a {
  color: $success-color;
  text-decoration: underline;
}
#captcha .inputinner p.check_note {
  text-align: center;
  line-height: 1.4;
}
#captcha .inputinner p.check_note_small {
  font-size: 12px;
  margin-bottom: 20px;
}
#captcha .inputinner p.check_note_pass {
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
}
#captcha #g-recaptcha {
  margin: 0 auto 10px auto;
  width: 304px;
}
#captcha .btnWrap {
  margin: 0 auto 30px auto;
  width: 200px;
}
