/* simple styles
**************************************************/
/* width, height */
.w960 {
  width: 960px !important;
}
.w720 {
  width: 720px !important;
}
.w440 {
  width: 440px !important;
}
.w276 {
  width: 276px !important;
}
.w195 {
  width: 195px !important;
}
.wAuto {
  display: block;
  width: auto;
}

/* margin, padding */
.mHAuto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.mL10 {
  margin-left: 10px !important;
}
.mL280 {
  margin-left: 280px !important;
}
.mL300 {
  margin-left: 300px !important;
}
.mT0 {
  margin-top: 0px !important;
}
.mT8 {
  margin-top: 8px !important;
}
.mT16 {
  margin-top: 16px !important;
}
.mT24 {
  margin-top: 24px !important;
}
.mB0 {
  margin-bottom: 0px !important;
}
.mB5 {
  margin-bottom: 5px !important;
}
.mB19 {
  margin-bottom: 19px !important;
}
.mB10 {
  margin-bottom: 10px !important;
}
.mB16 {
  margin-bottom: 10px !important;
}
.mB20 {
  margin-bottom: 20px !important;
}
.mB30 {
  margin-bottom: 30px !important;
}
.mB40 {
  margin-bottom: 40px !important;
}
.mB60 {
  margin-bottom: 60px !important;
}
.p0 {
  padding: 0px !important;
}
.pB0 {
  padding-bottom: 0px !important;
}
.pB10 {
  padding-bottom: 10px !important;
}
.pB15 {
  padding-bottom: 15px !important;
}
.pB20 {
  padding-bottom: 20px !important;
}
.pT60 {
  padding-top: 60px !important;
}
.pT120 {
  padding-top: 120px !important;
}
.pT160 {
  padding-top: 160px !important;
}

/* other */
.taL {
  text-align: left !important;
}
.taC {
  text-align: center !important;
}
.taR {
  text-align: right !important;
}

.disp_in {
  display: inline !important;
}
.disp_inB {
  display: inline-block !important;
}
.disp_blk {
  display: block !important;
}

.posRe {
  position: relative !important;
}
.posAb {
  position: absolute !important;
}
.posFi {
  position: fixed !important;
}
.posSt {
  position: static !important;
}

.flL {
  float: left !important;
}
.flR {
  float: right !important;
}
.fl0 {
  float: none !important;
}

.clear {
  clear: both;
}
.cf {
  zoom: 1;
}
.cf:after {
  display: block;
  clear: both;
  content: "";
}

.fsSS {
  font-size: 0.9em !important;
}
.fsS {
  font-size: 10px !important;
}
.fsM {
  font-size: 14px !important;
}
.fsL {
  font-size: 14px !important;
}
.fsLL {
  font-size: 18px !important;
}
.fB {
  font-weight: bold;
}
.b0 {
  border: none !important;
}
.bd1 {
  display: inline-block;
  overflow: hidden;
  border: solid 1px #efefef;
}
.bd1 > img {
  margin: -1px;
}

.wsPreLine {
  white-space: pre-line;
}
.wbAll {
  word-break: break-all;
}

.u-ta-r {
  text-align: right;
}

.u-ta-c {
  text-align: center;
}