/* inputForm */
.inputForm {
}
.inputForm .mc_table > tbody > tr > th {
  padding-top: 16px;
  vertical-align: top;
  text-align: left;
  padding-right: 24px;
}
.inputForm .mc_table > tbody > tr > th > span {
  color: #333;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
}
.inputForm .mc_table > tbody > tr > td {
  vertical-align: top;
}
.inputForm .mc_table > tbody > tr.combine {
  border-bottom: none;
}
.inputForm .mc_table > tbody > tr.combine > th,
.inputForm .mc_table > tbody > tr.combine > td {
  padding-bottom: 0;
}
.inputForm .mc_table > tbody > tr > th.required {
  background: url(/assets/images/ico_required.png) right 14px no-repeat;
}

/* npc_inputForm */
.npc_inputForm {
  float: left;
  width: 380px;
  border-right: 1px solid #eceae5;
  padding: 20px 20px;
}
.npc_inputForm .npc_table > tbody > tr > th {
  width: 160px;
  vertical-align: middle;
  text-align: left;
}
.npc_inputForm .npc_table > tbody > tr > th > span {
  color: #333;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
}
.npc_inputForm .npc_table > tbody > tr > th.required {
  margin-top: -3px;
  background: url(/assets/images/ico_required.png) right center no-repeat;
}
.npc_inputForm .npc_table > tbody > tr > td {
  padding: 10px 20px;
}

/* oAuth login form */
#loginByOauth {

}
#loginByOauth .links {
  margin-top: 12px;
}
#loginByOauth .links li {
  background: url(/assets/images/ico_linkArrowR.png) right center no-repeat;
}
#loginByOauth .links li > a {
  display: block;
  padding: 15px 10px 15px 30px;
  font-size: 14px;
  font-weight: bold;
}
#loginByOauth .links li.tw > a {
  background: url(/assets/images/ico_twitter.png) 0 center no-repeat;
}
#loginByOauth .links li.fb > a {
  background: url(/assets/images/ico_facebook.png) 0 center no-repeat;
}
#loginByOauth .links li.am > a {
  background: url(/assets/images/ico_blog.png) 0 center no-repeat;
}

/*npc_links*/
/*#loginByOauth .npc_links{
  margin-top:20px;
}*/
#loginByOauth .npc_links li {
  margin-bottom: 15px;
}

/* loginForm */
.loginForm .inputForm,
#mdl_login .inputForm {
  float: left;
  margin: 0 0 20px 0;
  /*width:508px; */
}

.loginForm .inputForm table,
#mdl_login .inputForm table {
  width: 100%;
}
/* .login .inputForm th,
  #mdl_login .inputForm th {
    width:28px; */

/*.loginForm #autoLoginWrap,
#mdl_login #autoLoginWrap {
  margin-bottom :10px;
  text-align:center;
}*/
.loginForm #btnWrap,
#mdl_login #btnWrap {
  text-align: center;
}
.boxBottom {
  margin: 0 -15px;
  padding: 15px;
  background: $info-color;
}

@media (max-width: 640px) {
  .inputForm .mc_table {
    display: block;
    width: auto;
  }
  .inputForm .mc_table > tbody {
    display: block;
  }
  .inputForm .mc_table > tbody > tr > th {
    width: auto !important;
    display: block;
  }
  .inputForm .mc_table > tbody > tr > th.required {
    background: #fafafa url(/assets/images/ico_required.png) 90% 14px no-repeat;
  }
}
